// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-single-case-single-case-jsx": () => import("./../src/pages/SingleCase/SingleCase.jsx" /* webpackChunkName: "component---src-pages-single-case-single-case-jsx" */),
  "component---src-pages-about-about-jsx": () => import("./../src/pages/About/About.jsx" /* webpackChunkName: "component---src-pages-about-about-jsx" */),
  "component---src-pages-cases-cases-jsx": () => import("./../src/pages/Cases/Cases.jsx" /* webpackChunkName: "component---src-pages-cases-cases-jsx" */),
  "component---src-pages-landing-landing-jsx": () => import("./../src/pages/Landing/Landing.jsx" /* webpackChunkName: "component---src-pages-landing-landing-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

